import { memo, useCallback, useMemo, useRef, useState } from 'react';

import DeleteIcon from '@/assets/images/delete-icon-grey.svg';
import ArrowIcon from '@/assets/images/drop-down-arrow.svg';
import FolderIcon from '@/assets/images/folder-large.svg';
import RenameIcon from '@/assets/images/rename-icon-gray.svg';
import { DataRoomClientFile } from '@/common/types';
import { useDataRoom } from '@/contexts/overview/dataroom/utils';
import { MarveriIcon } from '@/pages/overview/common/MarveriIcon';
import { useOverview } from '@/pages/overview/common/utils';
import { DraggableDataRoomFolder } from '@/pages/overview/dataroom/content/common/DraggableDataRoomFolder';
import { Droppable } from '@/pages/overview/dataroom/content/common/Droppable';
import { RenameListItemForm } from '@/pages/overview/dataroom/content/common/RenameListItemForm';
import { DataRoomFileList } from '@/pages/overview/dataroom/content/file-list/DataRoomFileList';
import { trpcReact } from '@/utils/trpc';

interface OrganizedViewLayoutProps {
  path: string;
  files: DataRoomClientFile[];
  dataRoomInteraction: boolean | undefined;
  isParenFolderEmpty: boolean;
}

const OrganizedViewLayout = ({
  path,
  files,
  dataRoomInteraction,
  isParenFolderEmpty,
}: OrganizedViewLayoutProps) => {
  const isTrashFolder = useMemo(() => {
    return path === '/~Trash';
  }, [path]);

  const isRootFolder = useMemo(() => {
    return path === '/';
  }, [path]);

  const [depth, setDepth] = useState<number>(0);
  const [isFolderRenameActive, setIsFolderRenameActive] = useState<boolean>(false);
  const [hoveredFolder, setHoveredFolder] = useState('');
  const renameInputRef = useRef<HTMLInputElement>(null);

  const {
    matter: {
      number: matterNumber,
      client: { number: clientNumber },
      dataRoomFiles: allFiles,
      folderConfig,
    },
  } = useOverview();

  const {
    isFolderOpenByPath,
    setIsFolderOpenByPath,
    checkedFiles,
    setCheckedFiles,
    isFolderChecked,
    setIsFolderChecked,
    updateRenameFormStatus,
    updateSubFolders,
    newlyCreatedFolder,
  } = useDataRoom();

  const isOpen = useMemo(() => {
    const folders = path.split('/').slice(1);
    const areFoldersOpen = folders.map((_folder, index) => {
      return isFolderOpenByPath['/' + folders.slice(0, index + 1).join('/')];
    });
    return areFoldersOpen.every((val) => val);
  }, [isFolderOpenByPath, path]);

  const isVisible = useMemo(() => {
    const parentFolders = path.split('/').slice(1, -1);
    if (parentFolders.length < 1) {
      return true;
    } else {
      const areFoldersOpen = parentFolders.map((_folder, index) => {
        return isFolderOpenByPath['/' + parentFolders.slice(0, index + 1).join('/')];
      });
      return areFoldersOpen.every((val) => val);
    }
  }, [isFolderOpenByPath, path]);

  const displayPath = useMemo(() => {
    const folders = path.split('/');
    if (folders.length === 2 && folders[1] === '') {
      setDepth(0);
      return '';
    }
    setDepth(folders.length - 1);
    return folders[folders.length - 1];
  }, [path]);

  const bulkChangePath = trpcReact.dataRoom.bulkUpdatePath.useMutation();
  const updateEmptyFolders = trpcReact.dataRoom.updateEmptyFolders.useMutation();

  const emptyFolders = useMemo(() => {
    return folderConfig?.emptyFolders || [];
  }, [folderConfig?.emptyFolders]);

  const removeFromEmptyFolders = useCallback(
    (toRemove: string[]) => {
      updateEmptyFolders.mutate({
        clientMatterNumber: matterNumber,
        clientNumber: clientNumber,
        emptyFolders: emptyFolders.filter((f) => !toRemove.includes(f)),
      });
    },
    [clientNumber, emptyFolders, matterNumber, updateEmptyFolders],
  );

  const clickRenameFolder = useCallback((e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setIsFolderRenameActive(true);
  }, []);

  interface UpdateType {
    fileName: string;
    newPath: string;
  }

  const renameFolder = useCallback(
    async (newPath: string) => {
      setIsFolderRenameActive(false);
      const isEmpty = files.length === 0;
      const pathArray = path.split('/');
      let updates = [] as UpdateType[];
      allFiles.forEach((file) => {
        if (file.path.indexOf(path) === 0) {
          const folders = file.path.split('/');
          const newFolders = folders.map((folder) => {
            // replace the right part of the path with the new folder name
            if (folder === path.split('/').slice(-1)[0]) {
              return newPath;
            } else {
              return folder;
            }
          });
          updates = updates.concat([
            {
              fileName: file.name,
              newPath: newFolders.join('/'),
            },
          ]);
        }
      });
      await bulkChangePath.mutate({
        clientMatterNumber: matterNumber,
        clientNumber: clientNumber,
        updates: updates,
      });
      // handle empty folders
      if (isEmpty) {
        if (pathArray.length > 2) {
          const folders = path.split('/');
          const newFolders = folders.map((folder) => {
            if (folder === path.split('/').slice(-1)[0]) {
              return newPath;
            } else {
              return folder;
            }
          });
          updateEmptyFolders.mutate({
            clientMatterNumber: matterNumber,
            clientNumber: clientNumber,
            emptyFolders: [...emptyFolders.filter((f) => f !== path), newFolders.join('/')],
          });
          return;
        }
        const newPathWithSlash = '/' + newPath;
        updateEmptyFolders.mutate({
          clientMatterNumber: matterNumber,
          clientNumber: clientNumber,
          emptyFolders: [...emptyFolders.filter((f) => f !== path), newPathWithSlash],
        });
      } else {
        removeFromEmptyFolders([path]);
      }
    },
    [
      allFiles,
      bulkChangePath,
      clientNumber,
      emptyFolders,
      files.length,
      matterNumber,
      path,
      removeFromEmptyFolders,
      updateEmptyFolders,
    ],
  );

  const widthClass = useMemo(() => {
    if (depth < 2) {
      return { width: '100%' };
    } else {
      return { width: '100%', marginLeft: `${(depth - 1) * 32}px` };
    }
  }, [depth]);

  const toggleCollapse = useCallback(() => {
    if (!isFolderRenameActive) {
      setIsFolderOpenByPath((map) => {
        const newMap = { ...map };
        newMap[path] = !newMap[path];
        newMap['/'] = true;
        const storedOpenMapJson = JSON.stringify(newMap);
        localStorage.setItem('folderOpenMap', storedOpenMapJson);
        return newMap;
      });
      updateRenameFormStatus(false);
    }
  }, [isFolderRenameActive, path, setIsFolderOpenByPath, updateRenameFormStatus]);

  const onFolderCheck = useCallback(() => {
    if (!isOpen && !isFolderChecked[path]) toggleCollapse();
    const filesInFolder = allFiles.filter((file) => {
      const folderPath = path.endsWith('/') ? path : `${path}/`;
      return file.path === path || file.path.startsWith(folderPath);
    });

    const filteredFiles = filesInFolder.filter((file) => !checkedFiles.includes(file));

    setIsFolderChecked((prevState) => {
      const folderCheckStatus = {
        ...prevState,
        [path]: !prevState[path],
      };
      allFiles.forEach((file) => {
        const path = file.path;
        const pathArray = path.split('/');
        pathArray.forEach((_value, index) => {
          const checkPath = pathArray.slice(0, index + 1).join('/');
          if (!folderCheckStatus[checkPath]) {
            folderCheckStatus[checkPath] = false;
          }
        });
      });

      if (folderCheckStatus[path]) {
        setCheckedFiles((prevFiles) => [...prevFiles, ...filteredFiles]);
        updateSubFolders(path, true, folderCheckStatus);
      } else {
        setCheckedFiles((prevFiles) => prevFiles.filter((file) => !filesInFolder.includes(file)));
        updateSubFolders(path, false, folderCheckStatus);
      }

      return folderCheckStatus;
    });
  }, [
    allFiles,
    checkedFiles,
    isFolderChecked,
    isOpen,
    path,
    setCheckedFiles,
    setIsFolderChecked,
    toggleCollapse,
    updateSubFolders,
  ]);

  const checkForSubfolders = useMemo(() => {
    if (folderConfig && folderConfig.emptyFolders) {
      const myFolders = folderConfig.emptyFolders.filter((f) => f.startsWith(path));
      if (myFolders.length > 1) {
        return true;
      }
    }
    const filesInSubFolders = allFiles.filter((f) => {
      const folder = f.path;
      return folder.startsWith(path) && folder.length > path.length;
    });
    return filesInSubFolders.length > 0;
  }, [path, folderConfig, allFiles]);

  return (
    <>
      {isVisible && (
        <div className="flex" data-testid="data-room-folder">
          <div style={widthClass} id={path}>
            {!isRootFolder ? (
              <DraggableDataRoomFolder
                draggableId={path}
                currentDroppableId={path}
                disabled={isFolderRenameActive || isRootFolder || isTrashFolder}
                data-testid="draggable-data-room-folder"
                onMouseEnter={() => setHoveredFolder(path)}
                onMouseLeave={() => setHoveredFolder('')}
              >
                {(!isTrashFolder && hoveredFolder === path) || isFolderChecked[path] ? (
                  <div
                    data-testid="data-room-folder-checkbox"
                    className="flex items-center justify-center"
                  >
                    <input
                      checked={isFolderChecked[path]}
                      type="checkbox"
                      onChange={onFolderCheck}
                      className={`size-4 cursor-pointer appearance-none border border-light-border bg-marveri-background bg-contain bg-center bg-no-repeat checked:bg-[url('@/assets/images/check-icon-white.svg')]`}
                    />
                  </div>
                ) : (
                  <div className="flex items-center justify-center">
                    <div className="size-4 border border-transparent"></div>
                  </div>
                )}
                <div
                  className={`${
                    isParenFolderEmpty && depth > 1 ? '' : 'border-t border-light-border'
                  } group relative ml-3 flex h-[46px] w-full cursor-pointer items-center gap-[6px] ${
                    isOpen
                      ? 'border-b border-light-border bg-[#313131]'
                      : 'hover:bg-container-hover'
                  } ${isFolderChecked[path] && 'bg-marveri-muted-silver'} ${newlyCreatedFolder.folderPath === path && 'animate-highlight-fade'} `}
                >
                  <div data-testid="folder-drop-down-arrow" onClick={toggleCollapse}>
                    {isOpen ? (
                      <img src={ArrowIcon} alt="arrow-down" className="size-[16px] rotate-90" />
                    ) : (
                      <img src={ArrowIcon} alt="arrow-down" className="size-[16px]" />
                    )}
                  </div>
                  <div className="flex size-full flex-row justify-between" onClick={toggleCollapse}>
                    <div className="flex w-full items-center" data-testid="data-room-folder-title">
                      {isTrashFolder ? '' : <img src={FolderIcon} className="mr-2" />}
                      {isFolderRenameActive ? (
                        <RenameListItemForm
                          currentName={displayPath}
                          onSubmit={(newPath) => renameFolder(newPath)}
                          onClose={() => setIsFolderRenameActive(false)}
                          renameInputRef={renameInputRef}
                        />
                      ) : (
                        <span className="items-start">
                          {isTrashFolder ? (
                            <MarveriIcon
                              icon={DeleteIcon}
                              iconStyle="select-none shrink-0"
                              iconType="list-main"
                            />
                          ) : (
                            displayPath
                          )}
                        </span>
                      )}
                      {path !== '/' &&
                        !isTrashFolder &&
                        !isFolderRenameActive &&
                        dataRoomInteraction && (
                          <div
                            className="flex gap-[16px] pl-4 opacity-0 group-hover:opacity-100"
                            data-testid="data-room-folder-rename-buttons"
                          >
                            <div className="flex">
                              <MarveriIcon
                                icon={RenameIcon}
                                onClick={clickRenameFolder}
                                iconStyle="select-none shrink-0"
                                iconType="list-main"
                              />
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </DraggableDataRoomFolder>
            ) : (
              <></>
            )}
            {isOpen ? (
              <div>
                <DataRoomFileList
                  fileList={files}
                  dataRoomInteraction={dataRoomInteraction}
                  folderPath={displayPath}
                  hasSubFolders={checkForSubfolders}
                />
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      )}
    </>
  );
};

interface OrganizedViewProps {
  filesByPath: Record<string, DataRoomClientFile[]>;
  dataRoomInteraction?: boolean;
}

export const OrganizedView = memo(function OrganizedView({
  filesByPath,
  dataRoomInteraction = true,
}: OrganizedViewProps) {
  const rootFolderVisible = useMemo(() => {
    return Object.keys(filesByPath).includes('/');
  }, [filesByPath]);

  const rootFolderEmpty = useMemo(() => {
    return rootFolderVisible && filesByPath['/'].length === 0;
  }, [filesByPath, rootFolderVisible]);

  const adjustedFiles = useMemo(() => {
    if (rootFolderEmpty) {
      const newFiles = { ...filesByPath };
      delete newFiles['/'];
      return newFiles;
    } else {
      return filesByPath;
    }
  }, [filesByPath, rootFolderEmpty]);

  const checkForEmptyParentFolder = (path: string) => {
    const parentFolder = path.substring(0, path.lastIndexOf('/'));

    if (parentFolder) {
      const parentFolderLength = adjustedFiles[parentFolder]?.length;

      if (parentFolderLength === 0) {
        return true;
      }
    }

    return false;
  };

  return (
    <>
      {rootFolderVisible && !rootFolderEmpty ? (
        <div className="h-[5px]"></div>
      ) : (
        <Droppable droppableId={'/'}>
          <div className="h-[20px]"></div>
        </Droppable>
      )}
      {Object.keys(adjustedFiles)
        .sort((a, b) => {
          const newA = a.replaceAll('/', ' /');
          const newB = b.replaceAll('/', ' /');
          if (newA < newB) {
            return -1;
          } else if (newA > newB) {
            return 1;
          }
          return 0;
        })
        .map((path) => (
          <Droppable key={path} droppableId={path}>
            <OrganizedViewLayout
              path={path}
              files={adjustedFiles[path]}
              dataRoomInteraction={dataRoomInteraction}
              isParenFolderEmpty={checkForEmptyParentFolder(path)}
            />
          </Droppable>
        ))}
    </>
  );
});
