import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  KeyboardSensor,
  MouseSensor,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import OrganizeIcon from '@/assets/images/organize-icon.svg';
import { DataRoomLoader } from '@/components/DataRoomLoader';
import { ErrorContent } from '@/components/ErrorContent';
import { ToolStatus } from '@/components/ToolStatus';
import { useDataRoom } from '@/contexts/overview/dataroom/utils';
import { ScrollableDiv } from '@/pages/overview/common/ScrollableDiv';
import { organizeDocumentsByPath, useOverview } from '@/pages/overview/common/utils';
import { DataRoomAddNew } from '@/pages/overview/dataroom/content/common/DataRoomAddNew';
import { DataRoomButton } from '@/pages/overview/dataroom/content/common/DataRoomButton';
import { DownloadButton } from '@/pages/overview/dataroom/content/common/DownloadButton';
import { DragOverlayContent } from '@/pages/overview/dataroom/content/common/DragOverlayContent';
import { FileErrorModal } from '@/pages/overview/dataroom/content/common/FileErrorModal';
import { RedlineButton } from '@/pages/overview/dataroom/content/common/RedlineButton';
import { RenameOptions } from '@/pages/overview/dataroom/content/common/RenameOptions';
import { OrganizedView } from '@/pages/overview/dataroom/content/OrganizedView';
import { PdfViewer } from '@/pages/pdf-viewer/PdfViewer';
import { trpcReact } from '@/utils/trpc';

export const DataRoomContent = () => {
  const featureFlags = useFlags();
  const {
    matter: {
      number: matterNumber,
      client: { number: clientNumber, name: clientName },
      fileUploadPercentComplete,
      fileUploadErrored,
      folderConfig,
      erroredFileNames,
    },
    isPanelCollapsed,
  } = useOverview();

  const {
    isViewerOpen,
    setIsViewerOpen,
    setSelectedFile,
    selectedFile,
    setDraggedContent,
    selectedFileUrl,
    setIsCheckAllActive,
    isCheckAllActive,
    checkedFiles,
    setCheckedFiles,
    hasCheckedFiles,
    goToNextDocument,
    goToPreviousDocument,
    dataRoomFiles,
    changeFolderCheckStatus,
    setIsFolderChecked,
    updateRenameFormStatus,
    updateAllFolderSelection,
    selectSecondFileByName,
    secondSelectedFile,
    secondSelectedFileUrl,
    updateSubFolders,
    setIsFolderOpenByPath,
    newlyCreatedFolder,
    setNewlyCreatedFolder,
  } = useDataRoom();

  const [organizeLoading, setOrganizeLoading] = useState(false);
  const [isRenameClientNameActive, setIsRenameClientNameActive] = useState(false);
  const [isFileErrorModalOpen, setIsFileErrorModalOpen] = useState(false);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      const fileListComponent = document.getElementById('organized-view-component');
      const dataRoomButtons = document.querySelector('[data-testid="data-room-buttons"]');
      const headerCheckBox = document.querySelector('[data-testid="data-room-header-checkbox"]');
      const dataRoomSidebar = document.querySelector('[data-testid="right-panel-sidebar"]');
      const pdfViewer = document.querySelector('[data-testid="pdf-viewer"]');
      const clientMatterNameContainer = document.querySelector(
        '[data-testid="rename-client-name-form"]',
      );
      if (
        fileListComponent?.contains(event.target as Node) ||
        dataRoomButtons?.contains(event.target as Node) ||
        headerCheckBox?.contains(event.target as Node) ||
        dataRoomSidebar?.contains(event.target as Node) ||
        pdfViewer?.contains(event.target as Node) ||
        clientMatterNameContainer?.contains(event.target as Node)
      )
        return;
      else {
        setSelectedFile('');
        updateRenameFormStatus(false);
        setIsRenameClientNameActive(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [checkedFiles.length, setSelectedFile, updateRenameFormStatus]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
        event.preventDefault();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (fileUploadPercentComplete >= 100 && erroredFileNames.length > 0) {
      setIsFileErrorModalOpen(true);
    }
  }, [erroredFileNames.length, fileUploadPercentComplete]);

  useEffect(() => {
    const hasRenameDocumentSetBeenCalled = localStorage.getItem('renameDocumentSetActive');

    if (hasRenameDocumentSetBeenCalled === 'false') {
      setIsRenameClientNameActive(true);
      localStorage.setItem('renameDocumentSetActive', 'true');
    }
  }, []);

  const pathOrganizedDocuments = useMemo(
    () => organizeDocumentsByPath(dataRoomFiles),
    [dataRoomFiles],
  );

  const updateEmptyFolders = trpcReact.dataRoom.updateEmptyFolders.useMutation();

  const allDocumentsSelected = useMemo(
    () => dataRoomFiles.length === checkedFiles.length,
    [checkedFiles.length, dataRoomFiles.length],
  );

  const dataRoomDownload = useMemo(() => {
    if (!allDocumentsSelected) {
      return organizeDocumentsByPath(checkedFiles);
    } else {
      const filesAndFolders = { ...pathOrganizedDocuments };
      const currentPaths = Object.keys(filesAndFolders);
      const emptyFolders = folderConfig?.emptyFolders || [];
      emptyFolders.forEach((folder) => {
        if (!currentPaths.includes(folder)) {
          filesAndFolders[folder] = [];
        }
      });
      return filesAndFolders;
    }
  }, [allDocumentsSelected, checkedFiles, folderConfig?.emptyFolders, pathOrganizedDocuments]);

  const dataRoomFileNames = useMemo(() => {
    return dataRoomFiles.map((file) => file.name);
  }, [dataRoomFiles]);

  const filesAndEmptyFolders = useMemo(() => {
    const filesByPath = pathOrganizedDocuments;
    const folders = folderConfig?.emptyFolders || [];
    folders.forEach((folder: string) => {
      if (!Object.keys(filesByPath).includes(folder)) {
        filesByPath[folder] = [];
      }
    });
    if (!Object.keys(filesByPath).includes('/~Trash')) {
      filesByPath['/~Trash'] = [];
    }
    const currentPaths = Object.keys(filesByPath);
    currentPaths.forEach((path) => {
      const pathArray = path.split('/');
      pathArray.forEach((_value, index) => {
        const checkPath = pathArray.slice(0, index + 1).join('/');
        if (!currentPaths.includes(checkPath) && checkPath.length > 0 && checkPath !== '/') {
          filesByPath[checkPath] = [];
        }
      });
    });
    return filesByPath;
  }, [pathOrganizedDocuments, folderConfig?.emptyFolders]);

  const setAllFilesChecked = useCallback(() => {
    setIsCheckAllActive((prevIsCheckAllActive) => {
      const newIsCheckAllActive = !prevIsCheckAllActive;
      if (newIsCheckAllActive) {
        const filteredDataRoomFiles = dataRoomFiles.filter(
          (file) => !file.path.startsWith('/~Trash'),
        );
        setCheckedFiles([...filteredDataRoomFiles]);
        changeFolderCheckStatus(true);
      } else {
        setCheckedFiles([]);
        changeFolderCheckStatus(false);
      }
      return newIsCheckAllActive;
    });
  }, [dataRoomFiles, changeFolderCheckStatus, setCheckedFiles, setIsCheckAllActive]);

  const noMatter = useMemo(() => {
    return !matterNumber;
  }, [matterNumber]);

  const filesAreProcessing = useMemo(() => {
    return !matterNumber || fileUploadPercentComplete != 100;
  }, [fileUploadPercentComplete, matterNumber]);

  const setAllFilesUnchecked = useCallback(() => {
    setCheckedFiles([]);
    setIsCheckAllActive(false);
  }, [setCheckedFiles, setIsCheckAllActive]);

  const DataRoomContentDisplay = () => {
    if (fileUploadErrored) {
      return <ErrorContent />;
    }
    if (noMatter || dataRoomFiles?.length === 0) {
      return (
        <DataRoomLoader fileUploadPercent={fileUploadPercentComplete} loaderFocus="Documents" />
      );
    }
    return (
      <div id="organized-view-component">
        <OrganizedView filesByPath={filesAndEmptyFolders} />
      </div>
    );
  };

  const { matter } = useOverview();

  const [newClientName, setNewClientName] = useState(matter.name);
  const renameInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isRenameClientNameActive) {
      if (renameInputRef && renameInputRef.current) {
        renameInputRef.current.focus();
      }
    }
  }, [isRenameClientNameActive, newClientName, renameInputRef]);

  const pointerSensor = useSensor(PointerSensor, {
    activationConstraint: {
      distance: 15,
    },
  });
  const mouseSensor = useSensor(MouseSensor);
  const touchSensor = useSensor(TouchSensor);
  const keyboardSensor = useSensor(KeyboardSensor);

  const sensors = useSensors(mouseSensor, touchSensor, keyboardSensor, pointerSensor);

  const bulkChangePath = trpcReact.dataRoom.bulkUpdatePath.useMutation();

  const setUncheckedAfterDrag = useCallback(() => {
    setCheckedFiles([]);
    changeFolderCheckStatus(false);
  }, [changeFolderCheckStatus, setCheckedFiles]);

  interface UpdateType {
    fileName: string;
    newPath: string;
  }

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      if (
        event.over &&
        event.active.data.current &&
        event.active.data.current.toString() != event.over.id.toString()
      ) {
        if (event.active.id.toString().startsWith('folder://')) {
          const oldPath = event.active.id.toString().slice(9);
          const currentFolder = oldPath.split('/').splice(-1)[0];
          const newPath = event.over.id.toString();
          if (oldPath === newPath || newPath.includes('/' + currentFolder)) return;
          const newPathAndTargetFolder =
            (newPath === '/' ? newPath : newPath + '/') + currentFolder;
          let updates = [] as UpdateType[];
          checkedFiles.forEach((file) => {
            updates = updates.concat([
              {
                fileName: file.name,
                newPath: file.path.replace(oldPath, newPathAndTargetFolder),
              },
            ]);
          });
          bulkChangePath.mutate({
            clientMatterNumber: matter.number,
            clientNumber: matter.client.number,
            updates: updates,
          });
          const emptyFolders = folderConfig?.emptyFolders || [];

          const movedEmptyFolders = emptyFolders.filter(
            (f) => f.startsWith(oldPath) && f !== oldPath,
          );

          const emptyFoldersToAdd = movedEmptyFolders.map((folder) => {
            return folder.replace(oldPath, newPathAndTargetFolder);
          });

          if (emptyFolders.includes(oldPath)) {
            emptyFoldersToAdd.push(newPathAndTargetFolder);
            updateEmptyFolders.mutate({
              clientMatterNumber: matterNumber,
              clientNumber: clientNumber,
              emptyFolders: [
                ...emptyFolders.filter((f) => !f.startsWith(oldPath)),
                ...emptyFoldersToAdd,
              ],
            });
          } else if (movedEmptyFolders.length > 0) {
            updateEmptyFolders.mutate({
              clientMatterNumber: matterNumber,
              clientNumber: clientNumber,
              emptyFolders: [
                ...emptyFolders.filter((f) => !f.startsWith(oldPath)),
                ...emptyFoldersToAdd,
              ],
            });
          }
        } else {
          const newPath = event.over.id.toString();
          let updates = [] as UpdateType[];
          checkedFiles.forEach((file) => {
            updates = updates.concat([
              {
                fileName: file.name,
                newPath: newPath,
              },
            ]);
            const emptyFolders = folderConfig?.emptyFolders || [];
            const actualFile = matter.dataRoomFiles.find(
              (f) => f.originalPathname === file.originalPathname,
            );
            if (actualFile && !emptyFolders.includes(actualFile.path)) {
              updateEmptyFolders.mutate({
                clientMatterNumber: matterNumber,
                clientNumber: clientNumber,
                emptyFolders: [...emptyFolders, actualFile.path],
              });
            }
          });
          bulkChangePath.mutate({
            clientMatterNumber: matter.number,
            clientNumber: matter.client.number,
            updates: updates,
          });
        }
      }
      setUncheckedAfterDrag();
      updateAllFolderSelection();
    },
    [
      bulkChangePath,
      checkedFiles,
      clientNumber,
      folderConfig?.emptyFolders,
      matter.client.number,
      matter.dataRoomFiles,
      matter.number,
      matterNumber,
      setUncheckedAfterDrag,
      updateAllFolderSelection,
      updateEmptyFolders,
    ],
  );

  const handleDragStart = (event: DragStartEvent) => {
    const draggableId = event.active.id.toString();
    if (draggableId.startsWith('folder://')) {
      const folder = draggableId.slice(9);

      setDraggedContent(folder);

      const files = dataRoomFiles.filter((file) => {
        const folderPath = folder.endsWith('/') ? folder : `${folder}/`;
        return file.path === folder || file.path.startsWith(folderPath);
      });
      const subfolders = folderConfig?.emptyFolders.filter(
        (emptyFolder) => emptyFolder === folder || emptyFolder.startsWith(`${folder}/`),
      );

      if (subfolders) {
        subfolders.forEach((folder) => {
          setIsFolderChecked((prevState) => {
            const folderCheckStatus = {
              ...prevState,
              [folder]: true,
            };

            updateSubFolders(folder, true, folderCheckStatus);

            return folderCheckStatus;
          });
        });
      }

      setCheckedFiles(files);
    } else {
      const fileName = draggableId;
      setDraggedContent('');
      if (!checkedFiles.some((checkedFile) => checkedFile.name === fileName)) {
        const file = dataRoomFiles.find((file) => file.name == fileName);
        if (file) {
          setCheckedFiles([...checkedFiles, file]);
        }
      }
    }
  };

  const organizeCheckedFiles = useCallback(() => {
    setOrganizeLoading(true);
    const newEmptyFolders = new Set(folderConfig?.emptyFolders || []);

    let updates = [] as UpdateType[];
    checkedFiles.forEach((file) => {
      if (file.docType !== 'Cap Table') {
        const alias = folderConfig?.folderAliases?.find(
          (a) => a.folderName === '/' + file.practiceArea,
        );
        const newPath = alias ? alias.alias : '/' + file.practiceArea;

        updates = updates.concat([
          {
            fileName: file.name,
            newPath: newPath,
          },
        ]);
        newEmptyFolders.delete(file.path);
        newEmptyFolders.add(newPath);
      }
    });
    bulkChangePath.mutate({
      clientMatterNumber: matter.number,
      clientNumber: matter.client.number,
      updates: updates,
    });

    newEmptyFolders.forEach((folder) => {
      setIsFolderOpenByPath((map) => {
        const newMap = { ...map };
        newMap[folder] = true;
        newMap['/'] = true;
        const storedOpenMapJson = JSON.stringify(newMap);
        localStorage.setItem('folderOpenMap', storedOpenMapJson);
        return newMap;
      });
    });

    setAllFilesUnchecked();
    changeFolderCheckStatus(false);

    updateEmptyFolders.mutate(
      {
        clientMatterNumber: matterNumber,
        clientNumber: clientNumber,
        emptyFolders: Array.from(newEmptyFolders),
      },
      {
        onSettled: () => {
          setOrganizeLoading(false);
        },
      },
    );
  }, [
    changeFolderCheckStatus,
    bulkChangePath,
    checkedFiles,
    clientNumber,
    folderConfig?.emptyFolders,
    folderConfig?.folderAliases,
    matter.client.number,
    matter.number,
    matterNumber,
    setAllFilesUnchecked,
    setIsFolderOpenByPath,
    updateEmptyFolders,
  ]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    event.stopPropagation();
    if (event.key === 'Escape') {
      setIsRenameClientNameActive(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewClientName(e.target.value);
  };

  const clientNameToUpdate = trpcReact.clientMatter.updateName.useMutation();
  const updateClientName = (event: React.FormEvent<HTMLFormElement>, newName: string | null) => {
    event.preventDefault();
    if (newName !== null) {
      clientNameToUpdate.mutate({
        number: matterNumber,
        clientNumber: clientNumber,
        name: newName,
      });
    }

    setIsRenameClientNameActive(false);
  };

  const reprocessMutation = trpcReact.dataRoom.reprocessDataroom.useMutation();
  const debugReprocessDataroom = useCallback(() => {
    reprocessMutation.mutate({
      clientMatterNumber: matterNumber,
      clientNumber: clientNumber,
    });
  }, [clientNumber, matterNumber, reprocessMutation]);

  return (
    <div
      className="flex cursor-default flex-col items-center bg-marveri-background text-[12px] text-marveri-white"
      data-testid="data-room-content-component"
    >
      <div className="w-[96%]">
        <div className="relative mt-[16px] flex w-full items-center">
          {isRenameClientNameActive ? (
            <form
              onSubmit={(event) => updateClientName(event, newClientName)}
              data-testid="rename-client-name-form"
              className="flex rounded-[5px] border border-light-border bg-marveri-background"
              onClick={(e) => e.stopPropagation()}
            >
              <input
                type="text"
                onChange={handleChange}
                value={newClientName ?? ''}
                onKeyDown={handleKeyDown}
                className="rounded-[5px] bg-inherit indent-2 text-[34px] font-bold outline-none"
                ref={renameInputRef}
              />
            </form>
          ) : (
            <h1
              className="cursor-pointer border border-transparent text-[34px] font-bold"
              onClick={() => setIsRenameClientNameActive(true)}
              data-testid="client-matter-title"
            >
              {!matter.name ? matter.client.name : matter.name}
            </h1>
          )}
        </div>
        <div
          className="flex w-full items-center justify-between py-[10px]"
          onClick={() => setIsRenameClientNameActive(false)}
        >
          <div className="flex h-[35px] w-full items-center" data-testid="data-room-buttons">
            <div className="flex" data-testid="tool-status-container">
              <ToolStatus matter={matter} isPanelCollapsed={isPanelCollapsed}></ToolStatus>
            </div>
            <div className="ml-auto flex flex-row gap-2">
              {featureFlags.redlineFeature && (
                <RedlineButton filesAreProcessing={filesAreProcessing} />
              )}
              <RenameOptions filesAreProcessing={filesAreProcessing} />
              <DataRoomButton
                title="Organize"
                icon={OrganizeIcon}
                onClick={organizeCheckedFiles}
                testId="organize-files-button"
                isDisabled={!hasCheckedFiles || filesAreProcessing || organizeLoading}
                filesAreProcessing={filesAreProcessing || organizeLoading}
              />
              <DownloadButton
                clientMatterNumber={matterNumber}
                clientNumber={clientNumber}
                clientName={clientName}
                files={checkedFiles}
                filesByPath={dataRoomDownload}
                setAllFilesUnchecked={setAllFilesUnchecked}
                filesAreProcessing={false}
              />
              <DataRoomAddNew
                clientMatterNumber={matterNumber}
                clientNumber={clientNumber}
                dataRoomFileNames={dataRoomFileNames}
              />
              {featureFlags.missingDocColumnSelector ? (
                <button
                  className="rounded-lg border-light-border p-[10px]"
                  onClick={debugReprocessDataroom}
                >
                  Reprocess Dataroom
                </button>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
        <div
          className="flex w-full border-b border-b-light-border py-2 font-bold text-marveri-light-silver"
          data-testid="data-room-header"
        >
          {!filesAreProcessing && (
            <div>
              <input
                checked={isCheckAllActive}
                type="checkbox"
                onChange={() => {
                  setAllFilesChecked();
                }}
                className={`size-4 cursor-pointer appearance-none border border-light-border bg-marveri-background bg-contain bg-center bg-no-repeat checked:bg-[url('@/assets/images/check-icon-white.svg')]`}
                data-testid="data-room-header-checkbox"
              />
            </div>
          )}
          <div className="ml-3 flex w-full gap-[50%]" data-testid="data-room-header-name-title">
            <h1>Name</h1>
          </div>
        </div>
        <ScrollableDiv
          containerStyle="h-[calc(100vh-250px)] w-full"
          selectedFile={selectedFile}
          newlyCreatedFolder={newlyCreatedFolder}
          setNewlyCreatedFolder={setNewlyCreatedFolder}
        >
          <DndContext onDragEnd={handleDragEnd} onDragStart={handleDragStart} sensors={sensors}>
            <DataRoomContentDisplay />
            <DragOverlay dropAnimation={null}>
              <DragOverlayContent />
            </DragOverlay>
          </DndContext>
        </ScrollableDiv>
        {!!selectedFile &&
        !!isViewerOpen &&
        (!!selectedFileUrl || selectedFile.summary === 'Cap Table') ? (
          <PdfViewer
            title={selectedFile.displayName}
            originalTitle={selectedFile.name}
            fileUrl={selectedFileUrl || 'Cap Table'}
            missingSignature={selectedFile.missingSignature}
            multipleDocuments={selectedFile.summary === 'Cap Table' ? false : true}
            closePdfViewer={() => setIsViewerOpen(false)}
            navigateBackFunction={goToPreviousDocument}
            navigateForwardFunction={goToNextDocument}
            selectSecondFile={selectSecondFileByName}
            secondSelectedFile={secondSelectedFile}
            secondSelectedFileUrl={secondSelectedFileUrl}
          />
        ) : (
          <></>
        )}
        <FileErrorModal
          isFileErrorModalOpen={isFileErrorModalOpen}
          setIsFileErrorModalOpen={setIsFileErrorModalOpen}
          erroredFiles={erroredFileNames}
        />
      </div>
    </div>
  );
};
