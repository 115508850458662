import { useEffect, useState } from 'react';

import BlueFileIcon from '@/assets/icons/blue-file-icon.svg';
import XIcon from '@/assets/icons/gray-x-icon.svg';
import SmallDropdownArrow from '@/assets/icons/small-dropdown-arrow.svg';
import { MarveriIcon } from '@/pages/overview/common/MarveriIcon';
import { RightsClause } from '@/pages/overview/financing-rights/common/utils';
import { FinanceRightSource } from '@/pages/overview/financing-rights/content/FinancingRightsContent';

interface FinancingRightsCardProps {
  offSetId: number;
  sourceId: string;
  sourceFile: string;
  sourceFileOriginalName: string;
  description: string;
  clausesRevised: RightsClause[];
  selectSourceFile: (fileName: string, highlight: number[]) => void;
  handleJumpToHighlight?: (highlights: number[]) => void;
  selectedClauseId: string;
  setSelectedClauseId: (val: string) => void;
  setIsDeleteDialogVisible: (val: boolean) => void;
  setSelectedSourceFile: (val: FinanceRightSource) => void;
}

export const FinancingRightsCard = ({
  offSetId,
  sourceId,
  sourceFile,
  sourceFileOriginalName,
  description,
  clausesRevised,
  selectSourceFile,
  handleJumpToHighlight,
  selectedClauseId,
  setSelectedClauseId,
  setIsDeleteDialogVisible,
  setSelectedSourceFile,
}: FinancingRightsCardProps) => {
  const [clausesVisible, setClausesVisible] = useState(false);
  const [updatedClausesWithId, setUpdatedClausesWithId] = useState<RightsClause[]>([]);

  useEffect(() => {
    const clausesRevisedWithId = clausesRevised.map((clause, index) => ({
      ...clause,
      id: `${Date.now()}-${index}-${offSetId}`,
    }));

    setUpdatedClausesWithId(clausesRevisedWithId);
  }, [clausesRevised, offSetId]);

  const handleClauseClick = (clauseLocation: string, clauseId: string) => {
    const parsedLocations = JSON.parse(clauseLocation);
    setClausesVisible(true);
    setSelectedClauseId(clauseId);
    if (handleJumpToHighlight) {
      handleJumpToHighlight(parsedLocations.locations);
    }
    selectSourceFile(sourceFileOriginalName, parsedLocations.locations);
  };

  const handleDeleteIconClick = () => {
    setSelectedSourceFile({ fileName: sourceFile, financeRightId: sourceId });
    setIsDeleteDialogVisible(true);
  };

  return (
    <div
      className="mb-[16px] flex flex-col gap-[14px] border-b-2 border-[#2f2f2f] pb-[12px] text-[14px]"
      data-testid="financing-rights-card"
    >
      <div
        className="relative flex items-center gap-[15px]"
        data-testid="financing-rights-card-description"
      >
        <div
          className="flex items-center gap-[15px]"
          onClick={() => setClausesVisible((prevState) => !prevState)}
        >
          <img src={SmallDropdownArrow} className={`${clausesVisible && 'rotate-90'} h-[8px]`} />
          <h1 className="w-[90%] cursor-pointer text-[#CECECE] hover:text-marveri-white">
            {description}
          </h1>
        </div>
        <MarveriIcon
          icon={XIcon}
          iconStyle="absolute right-0 top-0"
          iconType="other"
          onClick={handleDeleteIconClick}
        />
      </div>
      <div
        className="ml-[20px] flex cursor-pointer items-center gap-[9px] text-[#CECECE] hover:text-marveri-white"
        onClick={() => selectSourceFile(sourceFileOriginalName, [])}
        data-testid="financing-rights-card-source"
      >
        <img src={BlueFileIcon} />
        <span>{sourceFile}</span>
      </div>
      <div
        className={`${clausesVisible ? 'flex-col' : 'flex-row'} ml-[20px] flex cursor-default gap-[12px] bg-[#191919] px-[16px] py-[8px]`}
      >
        {updatedClausesWithId.map((revisedClause, index) => {
          return (
            <div
              key={index}
              className={`${selectedClauseId === revisedClause.id && 'bg-[#272727]'} flex cursor-pointer flex-col gap-[8px] rounded-[8px] px-[6px] pb-[4px] pt-[6px] hover:bg-[#272727]`}
              onClick={() => handleClauseClick(revisedClause.location, revisedClause.id)}
              data-testid={`financing-rights-card-revised-clause`}
            >
              <span className="text-white">{revisedClause.brief_description}</span>
              {clausesVisible && (
                <span className="w-[90%] italic">{`"${revisedClause.quotation}"`}</span>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};
